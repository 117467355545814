.remove-member-confirm {
    display: flex;
    flex-direction: column;

    .message {
        display: flex;
        align-items: center;
        margin-top: 8px;
        font-weight: 500;
    }

    .members {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 4px;
        margin-top: 8px;

        .member {
            &:not(:last-child) {
                &:after {
                    content: ', ';
                }

                margin-right: 4px;
            }
        }
    }
}

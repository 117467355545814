.backend_center-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // min-width: 896px;
    background-color: $base-background-color;

    &--performance-role {
        min-width: 840px;
    }
}

.backend_center-header {
    background-color: white;
    height: 46px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin: 8px 8px 0;
    border-radius: 12px;
}

.backend_center-title {
    color: var(--gray-color);
}

.backend_center-header-return {
    display: flex;
    flex: none;
    align-items: center;
    font-size: 16px;
    color: $backend-base-color;
    cursor: pointer;
    border-radius: 8px;
    padding: 4px 4px 4px 2px;

    &:hover {
        background-color: #ECEDED;
    }
}

.backend_center-header-divider {
    height: 14px;
    width: 1px;
    margin-left: 4px;
    margin-right: 8px;
    background-color: #E5E6EB;
}

.backend_center-main {
    flex: 1;
    overflow: hidden;
}

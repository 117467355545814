#root {
    height: 100%;
}

// 搜索结果变色
.term-in-search_result {
    > font {
        color: $primary-color;
    }
}

.compact-popover {
    .ant-popover-inner {
        border-radius: 8px;
    }

    .ant-popover-inner-content {
        padding: 4px;
    }
}

textarea::placeholder,
input::placeholder {
    color: #86909c !important;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/** flex layout 参考 tailwind https://tailwindcss.com/docs/flex **/
.flex {
    display: flex;
}
.flex-row {
    @extend .flex;
    flex-direction: row;
}
.flex-col {
    @extend .flex;
    flex-direction: column;
}

.flex-none {
    flex: none;
}
.flex-1 {
    flex: 1;
}

.shrink-0 {
    flex-shrink: 0;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}
/** flex layout end **/

.error-text {
    color: var(--error-color)
}

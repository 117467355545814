.plus-text {
    display: flex;
    align-items: center;
    height: 36px;

    &:hover {
        cursor: pointer;

        .plus {
            color: $primary-color;
            border: 1px dashed $primary-color;
        }

        .content {
            color: $primary-color;
        }
    }

    .plus {
        display: flex;
        height: 36px;
        width: 36px;
        line-height: 36px;
        font-size: 18px;
        color: #333333;
        border-radius: 50%;
        border: 1px dashed #B6BBC0;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }

    .content {
        font-size: 14px;
        color: #9E9EA7;
    }
}

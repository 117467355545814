.filter-trigger {
    display: flex;
    align-items: center;
    height: 32px;
    overflow: hidden;
    padding: 0 8px;
    border-radius: 8px;
    border: 1px solid #E5E6EB;
    background-color: #FFF;
    color: var(--gray-color);
    cursor: pointer;

    .filter-trigger-text {
        flex: 1;
        overflow: hidden;
        margin: 0 2px;
    }

    &.filter-active {
        color:  $primary-color;
        background-color: rgba($color: $primary-color , $alpha: .1);
        border-color: rgba($color: $primary-color , $alpha: .3);
    }
}

.filter-content-container {
    display: flex;
    flex-wrap: nowrap;
    background-color: #F5F6F7;
    border-radius: 12px;
    padding: 18px 12px 8px;

    .filter-item-container {
        &:not(:first-child) {
            margin-left: 48px;
        }
    }

    .filter-item-input {
        margin-top: 8px;
    }
}

@charset 'UTF-8';

.member_selector-container {
    border-radius: 14px;
    border: 1px solid $border-main-color;
    height: 438px;

    overflow: hidden;
}

.member_selector-trigger {
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    width: fit-content;
    margin-left: 4px;
}

// 人员选择组件
#Members_Selector {
    .ant-popover-arrow {
        display: none;
    }
}

.search-select-container {
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;

    .search-side {
        width: 409px;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-right: 1px solid #EBEBEB;
        padding: 20px;
        overflow: auto;
    }

    .tree-side {
        overflow: auto;
        flex: 1;
    }

    .search-result-placeholder {
        width: 100%;
    }

    .button-group {
        text-align: right;
        margin-top: 20px;

        .ant-btn-primary {
            margin-left: 20px;
        }
    }
}

.members-search-container {
    overflow: auto;

    .select-icon {
        width: 36px;
        display: block;
        font-size: 14px;
        text-align: center;
    }
    .show-icon {
        color: #1677FF;
    }
    .hidden-icon {
        color: rgba(255,255,255,0);
    }
    .disable-icon {
        color: #E8E8EA;
    }
}

.selected_members-tag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    input {
        flex: 1;
        min-width: 200px;
        outline: none;
        border: none;

        &::placeholder {
            opacity: 1;
            color: #BDC1C7;
        }
    }
}

.search-multiple_block {

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .search-result_item {
        cursor: pointer;

        &:not(.disabled):hover {
            background-color: #DFE4EA;
            border-radius: 8px;
        }

        &.disabled {
            cursor: not-allowed;
            color: #BDC1C7;
        }
    }

    .select-icon {
        width: 36px;
        display: block;
        font-size: 14px;
        text-align: center;
    }

    .show-icon {
        color: #1677FF;
    }

    .hidden-icon {
        color: rgba(255,255,255,0);
    }

    .disable-icon {
        color: #E8E8EA;
    }
}

.iai-icon {
    display: inline-block;
    line-height: 0;
    text-align: center;
    vertical-align: -0.125em;
    overflow: hidden;

    > svg {
        width: 1em;
        height: 1em;
        fill: currentColor;
    }

    &.highlight-icon {
        border-radius: 3px;
        padding: 3px;

        &:hover {
            background-color: #ECEDED;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon-hover {
    &:hover {
        color: $primary-color;
    }
}

.pointer-icon {
    cursor: pointer;
}

.collapse-item {
    background-color: white;
    padding: 8px 10px;
    border-radius: 8px;

    .ant-table-wrapper {
        margin-top: 8px;
    }
}

.collapse-item-active {
    .collapse-icon > svg {
        transform: rotate(90deg);
    }
}

.collapse-header {
    display: flex;
    align-items: center;
    user-select: none;
}

.collapse-icon {
    font-size: 16px;
    padding: 2px;
    border-radius: 4px;

    > svg {
        transition: all 0.3s;
    }

    &:hover {
        background-color: #eceded;
    }
}

.collapse-title {
    margin-left: 2px;
}

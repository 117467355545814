.properties-table {
    // 还原设计稿 - 覆盖 ant-table-* 样式
    // 一定程度上并不通用，如果希望通用的前提下 https://github.com/ant-design/ant-design/blob/4.x-stable/components/style/themes/default.less
    .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before
    {
        background-color: transparent;
    }

    .ant-table-thead > tr > th:first-child,
    .ant-table-tbody > tr > td:first-child{
        padding-left: 32px;
    }

    .ant-table-thead {
        > tr > th {
            font-weight: normal;
            color: var(--gray-color);
        }

        .ant-checkbox + span {
            padding-right: 0;
        }
    }

    .ant-table-row  {
        position: relative;

        &:hover {
            .draggable-row-bar {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .draggable-row-bar {
        display: none;
        cursor: move;
        position: absolute;
        height: 100%;

        $DRAG_WIDTH: 30px;
        width: $DRAG_WIDTH;
        top: 0;
        left: 0;
    }
}

.member-role-checkbox {
    width: 75px;
    overflow: hidden;
    white-space: nowrap;

    > span:nth-child(2) {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.property-name-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span:not(.iai-icon) {
        cursor: text;
    }

    > .iai-icon {
        visibility: hidden;
    }

    &:hover {
        > .iai-icon {
            visibility: visible;
        }
    }
}

.add-property-btn {
    .iai-icon {
        color: #86909C;
    }

    .ant-btn {
        border-radius: 4px;
    }

    .add-property-item-btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .add-property-type-btn {
        visibility: hidden;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        padding-left: 4px;
        padding-right: 4px;
    }

    &:hover {
        > .ant-btn {
            transition-duration: 0.1s;
            border-color: #C9CDD4;
        }
        .add-property-type-btn {
            visibility: visible;
        }
    }
}

// 列类型相关
.custom-field_type-list {
    display: flex;
    flex-direction: column;
    grid-row: 2px;
}

.dropdown-option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    line-height: 38px;
    padding: 0 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #dfe4ea;
    }
}

.dropdown-option-content {
    display: inline-flex;
    align-items: center;
}

.dropdown-option-label {
    margin-left: 8px;
    white-space: nowrap;
}

.dropdown-option-icon {
    font-size: 18px;
}

.i-field-f-item-preview {
    line-height: 32px;
    width: 100%;
    display: block;
    background: #F7F8FA;
    border-radius: 8px;
    border: 1px solid #F2F3F5;
    padding-left: 12px;
    user-select: none;
    cursor: no-drop;
}

.i-field-f-select-options {
    .field-option-group {
        margin-top: 16px;
        &:first-child {
            margin-top: 8px;
        }
    }

    .field-option-group-name {
        line-height: 1;
        color: #86909C;
    }

    .i-field-f-option-wrapper:not(:first-child) {
        margin-top: 8px;
    }

    .draggable-option {
        border: 1px solid transparent;
    }

    .i-field-f-error-option {
        .draggable-option {
            border-color: var(--error-color);
        }
    }

    .i-field-f-select-add_btn {
        width: 179px;
        margin-top: 6px;
    }
}

.draggable-option {
    display: flex;
    height: 32px;
    width: 179px;
    align-items: center;
    cursor: move;
    border-radius: 8px;
    padding: 0 32px;
    background-color: #fafafa;
    position: relative;

    .draggable-o-value {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .draggable-o-operations {
        position: absolute;
        font-size: 16px;
        display: none;
    }

    &.disable-drag {
        cursor: default;
    }
    &:hover {
        .draggable-o-operations {
            display: block;
        }
    }
}

.priority-option {
    width: 100px;
    padding: 0;
    justify-content: center;

    .draggable-o-value {
        flex: none;
        flex-shrink: 1;
    }
}

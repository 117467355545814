.backend-role-management-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .backend-role-management-header {
        flex: none;
    }
}

.backend-role-management-header {
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid $border-main-color;
}

.backend-role-management-content {
    flex: 1;
    overflow: hidden;
    display: flex;
}

.role-log-btn {
    height: 31px;
    color: $primary-color;
    border-radius: 8px;
    background-color: rgba($primary-color, 0.1);
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--performance-role {
        margin-right: 36px;
    }

    > .iai-icon {
        margin-right: 4px;
    }
}

.role-management_roles-sidebar {
    width: 139px;
}

.role-edit-form {
    display: flex;
    flex-direction: column;

    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;

        .ant-btn {
            &:not(:first-child) {
                margin-left: 12px;
            }
        }
    }
}

.role-setting-controller {
    padding: 16px 0px 6px 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;

    .name {
        display: flex;
        align-items: center;
        height: 26px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .description {
        font-size: 14px;
        font-weight: 400;
        color: #9E9EA7;
        margin-left: 8px;
    }

    .location-text {
        color: $primary-color;
        white-space: nowrap;
        cursor: pointer;
    }

    .mx-16 {
        margin-right: 16px;
        margin-left: 16px;
    }
}

.backend-role-setting {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    overflow: hidden;

    .backend-role-setting-scroll {
        flex: 1;
        overflow-y:auto;
        overflow-x: hidden;

        .member-go-to {
            display: flex;
            justify-content: center;
            color: #9E9EA7;
            font-size: 14px;

            .introduce {
                margin-right: 6px;
            }

            .go-to {
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}

.backend-switcher {
    display: flex;
    align-items: flex-end;
    margin: 0 16px;
    border-bottom: 1px solid $border-main-color;

    .tab {
        padding-bottom: 16px;

        font-size: 14px;
        color: var(--gray-color);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 24px;
        }
    }

    .tab-active {
        font-weight: 500;
        color: $primary-color;
        position: relative;

        &::before {
            content: ' ';
            position: absolute;
            bottom: 0;
            background-color: $primary-color;
            display: inline-block;
            height: 3px;
            width: 100%;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
        }
    }
}

.backend-authorization-setting {
    display: flex;
    flex-direction: column;


    font-size: 14px;
    color: #3D3D4E;

    .module {
        height: 20px;
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .feature {
        display: inline-block;
        margin-right: 24px;
        height: 20px;
        margin-bottom: 12px;
    }

    .module-features-container {
        border-radius: 12px;
        background: #F5F6F7;
        padding: 12px 14px 0;
        margin-left: 24px;
    }
}

.member-table-top {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .filter {
        width: 260px;
    }
}

.member-buttons {
    display: flex;

    .ant-btn {
        &:not(:first-child) {
            margin-left: 12px;
        }
    }
}

.member-table-item {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 13px 0px;
    line-height: 21px;
    border-bottom: 1px solid #F2F2F2;

    .cell-member {
        width: 222px;
        flex: none;
        padding: 0px 12px;
        overflow: hidden;
        display: flex;

        .checked {
            margin-right: 6px;
        }

        .ant-checkbox-wrapper::after {
            content: unset;
        }
    }

    .cell-ranges {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 2px;
        padding: 0px 12px;
        align-items: center;

        .tag {
            // padding: 6px 8px;
            background-color: #E8E9E9;
            border-radius: 4px;
            color: $backend-base-color;
            border-color: #E8E9E9;

            &:not(:last-child) {
                margin-right: 2px;
            }
        }

        .add {
            margin-left: 8px;
            cursor: pointer;
            color: $primary-color;
        }
    }

    .cell-operation {
        display: flex;
        align-items: center;
        width: 60px;
        flex: none;

        .iai-icon {
            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    .cell-normal-content {
        padding: 0px 12px;
        flex: 1;
        overflow: hidden;
    }

}

.member-table-header {
    background-color: #F1F2F6;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin-top: 12px;
    color: var(--gray-color);
    font-weight: 500;
    border-bottom: unset;
}

.member-table-batch {
    height: 44px;
    padding-top: 6px;
    align-items: center;
    justify-content: space-between;
    display: flex;

    .info {
        font-size: 14px;

        .select-number {
            padding: 0 4px;
            color: $primary-color;
        }

        .cancel-select {
            margin-left: 16px;
            cursor: pointer;
            color: $primary-color;
        }
    }
}

// 操作日志
.role-management-logs-conatiner {
    display: flex;
    flex-direction: column;
    height: 560px;

    .role-management-logs-header,
    .filter-content-container {
        flex: none;
    }

    .filter-content-container {
        margin-top: 8px;
    }
}

.role-management-logs-header {
    display: flex;
    align-items: center;

    .filter-trigger {
        margin-left: 12px;
    }
}

.backend_center-organization-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
}

.backend_center-organization-sidebar {
    flex: 260px 0 0;
    overflow: hidden;
    border-right: 1px solid $border-main-color;

    display: flex;
    flex-direction: column;

    .sync-organization-btn {
        flex: none;
        margin: 8px;
    }
}

.organization-content {
    flex: 1;
    overflow: hidden;
}

.department-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 0;

    .department-info-title {
        flex: none;
        color: #3D3D4E;
        font-size: 16px;
        font-weight: bold;
        padding: 0 16px;
    }

    .department-info-content {
        flex: 1;
        overflow: auto;
        padding: 0 16px;
    }

    .subordinate-info-block {
        padding: 24px 0 18px;

        &:not(:first-child) {
            border-top: 1px solid #E8E8EA;
        }
    }

    .subordinate-content-title {
        color: #6E6D7A;
        line-height: 1;

        .title-text {
            margin-left: 8px;
        }
    }

    .subordinate-list {
        display: flex;
        flex-direction: column;

        .single-normal-user-container {
            padding-left: 22px;

            &:first-child {
                margin-top: 12px;
            }

            &:hover {
                background-color: #DFE4EA;
                border-radius: 8px;
            }
        }
    }

    .department-no_staff {
        display: flex;
        align-items: center;
        height: 56px;
        margin: 12px 22px 0;
        color: #BDC1C7;
        line-height: 1;
    }

    .add-single_member {
        margin-left: 4px;
        color: $primary-color;
    }
}

.staff-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 0;

    .staff-info-title {
        flex: none;
        color: #3D3D4E;
        font-size: 16px;
        font-weight: bold;
        padding: 0 16px;
        margin-bottom: 24px;
    }

    .staff-info-content {
        flex: 1;
        overflow: auto;
        padding: 0 16px;
    }

    .staff-info-content-top {
        display: flex;
        align-items: center;
    }

    .staff-info-avatar {
        flex: none;
        margin: 0 12px 0 35px;
    }

    .staff-info-departments {
        display: flex;
        flex-direction: column;
        line-height: 32px;
        align-items: flex-start;
    }

    .admin_unit_icon {
        margin-left: 4px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-item-label {
        width: 78px;

        > label {
            color: #6E6D7A;
        }
    }

    .ant-form-item-control {
        color: #3D3D4E;
    }
}

.staff-info-block {
    position: relative;

    &:not(:last-child) {
        border-bottom: 1px solid #E8E8EA;
    }
    padding: 24px 0;
}

.backend_center-organization-warning_nested {
    display: flex;
    flex-direction: column;
    height: 100%;

    .backend_center-organization-warning_tip {
        flex: none;
    }

    .backend_center-organization-container {
        flex: 1;
    }
}

.backend_center-organization-warning_tip {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: rgba($color: #FBBC05, $alpha: 0.16);

    .iai-icon {
        margin-right: 9px;
    }

    .adjustment-btn {
        margin-left: 8px;
        color: $primary-color;
        cursor: pointer;
    }
}

.platform-modify-organization-modal {
    .ant-modal-body {
        padding: 8px 0 16px;
    }
}

.platform-modify-organization-guide {
    display: flex;
    flex-direction: column;
    height: 400px;

    .platform-modify-guide-content {
        flex: 1;
        overflow: auto;
        padding: 0 32px;
    }

    p {
        color: #6E6D7A;
        font-size: 16px;
        margin-bottom: 12px;
    }

    img {
        max-width: 100%;
        margin-bottom: 24px;
    }

    .platform-modify-guide-btns {
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        >.ant-btn {
            width: 110px;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }
}

.staff-datameta-container {
    display: flex;
    height: 100%;
    padding: 12px;
    overflow: hidden;
    flex-direction: column;

    .staff-datameta-header {
        flex: none;
        margin-bottom: 8px;
        text-align: right;
    }

    .editable-list-container {
        flex: 1;
        height: 100%;
        overflow: auto;
    }
}

.delete-comfirm-dialog .ant-modal-body {
    padding: 24px;
}

.delete-comfirm-dialog-wrapper {
    display: flex;

    >.iai-icon {
        flex: none;
        font-size: 22px;
        color: $danger-color;
        margin-right: 12px;
    }

    .delete-comfirm-dialog-content {
        flex: 1;
        overflow: hidden;
    }
}

.delete-comfirm-title {
    color: $backend-base-color;

    .highlight-title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 500;

    }
}

.delete-comfirm-input {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;

    .ant-form {
        flex: 1;
        overflow: hidden;
    }

    .delete-confirm-shortcut {
        flex: none;
        cursor: pointer;
        line-height: 32px;
        margin: 0 8px;
        color: $primary-color;
    }
}

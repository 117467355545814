@mixin inputs-border-radius {
    border-radius: 8px;
}

.ant-input {
    @include inputs-border-radius;
}

.ant-input-affix-wrapper {
    @include inputs-border-radius;

    .ant-input {
        border-radius: unset;
    }
}

.ant-input-number {
    @include inputs-border-radius;
}

.ant-input-number-group-addon {
    @include inputs-border-radius;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @include inputs-border-radius;
}

.ant-picker {
    @include inputs-border-radius;
}

.ant-tag {
    border-radius: 4px;
}

// 按钮样式修改为
.ant-btn {
    border-radius: 8px;

    >.iai-icon+span {
        margin-left: 4px;
    }
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
    // opacity: .8;
    color: #fff;
    background-color: #ff98b2;
    border-color: #ff98b2;
}

// 将ant design Select组件的鼠标hover的背景色覆盖掉
.ant-select-dropdown {
    padding: 0;

    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: #ECECEC;
    }

    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: #ECECEC;
    }
}

// cover Modal's default style
.ant-modal {
    padding: 0;
}

.ant-modal-content {
    border-radius: 16px;
}

.ant-modal-close-x {
    height: 68px;
    width: 68px;
    line-height: 68px;
}

.ant-modal-header {
    padding: 24px;
    border: none;
    border-radius: 16px 16px 0 0;
}

.ant-modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
}

.ant-modal-body {
    padding: 10px 24px 24px;
}

// cover Popover's default style
.ant-popover-inner {
    border-radius: 16px;
}

.no-arrow-popover {
    padding: 0;

    .ant-popover-arrow {
        display: none;
    }
}

.ant-tooltip-inner {
    padding: 8px 12px;
    background-color: $backend-base-color;
    border-radius: 6px;
}
.ant-tooltip-arrow-content::before {
    background: $backend-base-color;
}
.main-content-layout-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;

    .layout1-header {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: $horizontal-space 12px;
        height: 64px;
        flex: none;
    }

    .backend-switcher {
        flex: none;
    }

    .layout1-content {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: $horizontal-space 0;
        background-color: #FAFAFA;
    }

    .layout1-footer {
        padding: $horizontal-space;
        flex: none;
    }

    .main-title {
        flex: 1;
        line-height: 26px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .sub-title {
        font-size: 14px;
        font-weight: 400;
        color: #9E9EA7;
        margin-left: 8px;
    }

    .layout1-scroll-wrapper {
        padding: 0 $horizontal-space;
        flex: 1;
        overflow: auto;

        > :not(:first-child) {
            margin-top: 8px;
        }
    }
}

.backend-enterprise_info {
    background-color: $base-background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

    .enterprise_info-container {
        flex: 1;
        overflow: auto;
        display: flex;
    }
}

.enterprise_data-order-list {
    overflow: hidden;
    border-radius: 8px;
    background-color: white;
    width: 100%;
}

.enterprise_data-field-item {
    flex: none;
    width: 254px;
    height: 150px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;

    .enterprise_data-label {
        margin-top: 16px;
        margin-bottom: 0;
        color: var(--gray-color);

        > span {
            vertical-align: middle;
        }
    }

    .enterprise_data-item-content {
        margin-top: 8px;

        > span {
            vertical-align: baseline;
            font-size: 30px;
        }
    }

    .ant-btn {
        vertical-align: middle;
        margin-top: 8px;
    }

    .enterprise_data-item-extra {
        font-size: 12px;
        color: #86909C;
    }

    .contact-img {
        background-color: white;
        max-width: 90px;
    }
}

.enterprise_data-group-content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.enterprise_data-order-group {
    padding: 16px 24px;

    &:not(:first-child) {
        border-top: 1px solid #E5E6EB;
    }

    .enterprise_data-group-title {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .enterprise_data-group-subtitle {
        margin-left: 8px;
        font-size: 12px;
        font-weight: normal;
        color: #86909C;
    }
}

.empty-content-container {
    flex: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-content-img {
    max-height: 128px;
    max-width: 128px;
}

.empty-content-tips {
    color: #86909C;
    margin-top: 8px;
}

.organization-tree {
    height: 100%;
    width: 100%;
    user-select: none;
    overflow-y: auto;
    padding: 8px;
    font-size: 14px;

    .ant-tree {
        .ant-tree-switcher {
            color: #CCCCCC;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                color: $primary-color;
            }
        }

        .ant-tree-treenode {
            border-radius: 8px;
            margin-bottom: 2px;
            padding: 0;
        }

        .ant-tree-treenode:hover, .ant-tree-treenode-selected {
            transition: all .3s;
            background-color: #DFE4EA;
        }

        .ant-tree-node-content-wrapper {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            font-size: 14px;
            color: #3D3D4E;
            line-height: 20px;

            &.ant-tree-node-selected, &:hover {
                background: unset;
            }

            .ant-tree-iconEle {
                height: auto;
                width: auto;
            }

            .ant-tree-title {
                flex: 1 1 auto;
                margin-left: 6px;

                .ant-checkbox-wrapper {
                    padding-left: 6px;
                }
            }
        }
    }

    .tree-title-content {
        display: flex;
        align-items: center;
    }

    // 公司根节点
    .root-enterprise {
        > .ant-tree-node-content-wrapper {
            height: 52px;
            font-weight: bold;
        }

        .ant-tree-switcher {
            display: none;
        }
    }

    // // 部门节点
    .single-department {
        .ant-tree-node-content-wrapper {
            height: 36px;
            flex: 1;
            overflow: hidden;

            .ant-tree-title {
                overflow: hidden;

                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .ant-tree-iconEle {
            font-size: 20px;
            color: #1677FF;
        }
    }

    // 员工节点
    .single-staff {
        .ant-tree-node-content-wrapper {
            height: 52px;
        }

        .staff-avatar {
            display: inline-block;
        }
    }

    .single-normal-user-container {
        height: auto;
    }

    .ant-tree-checkbox {
        order: 99;
        align-self: center;
        margin: 0 6px 0;
    }
}

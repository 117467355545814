.authorise-management {
    width: 100%;
    height: 100%;
}

.authorise-list-tabs {
    height: 100%;
    overflow: hidden;

    .ant-tabs-nav {
        margin: 0;
        padding: 0 24px;
        color: #666666;

        &::before {
            border: none;
        }
    }

    .ant-tabs-ink-bar {
        border-radius: 2px;
    }

    .ant-tabs-tab  {
        padding: 16px 0 12px;
        line-height: 24px;
        font-size: 16px;
    }

    .authorise-list-search_input {
        width: 220px;
        margin-left: 24px;
    }

    .ant-tabs-content-holder {
        padding: 8px;
        background-color: #FAFAFA;
    }

    .ant-tabs-content {
        height: 100%;

        .ant-tabs-tabpane {
            height: 100%;
        }
    }
}

.members-management-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    padding: 14px 16px;
}

.members-management-header-operation {
    flex: none;

    > .member_selector-trigger:not(:first-child) {
        margin-left: 12px;
    }

    .ant-btn {
        display: inline-flex;
        align-items: center;
        color: #6E6D7A;
    }

    .iai-icon {
        font-size: 16px;
    }
}

.members-management-list {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    margin-top: 16px;
}

// 列表头容器
.members-list-header-row {
    flex: none;
    color: #4E596C;
    font-weight: bold;
    background-color: #F2F3F5;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.members-list-scroller_container {
    flex: 1;
    overflow-y: auto;

    ul {
        list-style: none;
        padding: 0;
        color: #3D3D4E;
    }
}

// 列表头、列表项都使用同一个layout样式
.members-list-row_layout {
    display: flex;
    align-items: center;
    line-height: 1;
    border-bottom: 1px solid #F2F2F2;

    > span,
    > div {
        padding: 16px;
    }

    .row_layout-cell-scrollbar {
        padding: 0;
    }

    .row_layout-name {
        flex: 175px 0 0;
        overflow: hidden;

        > span {
            word-break: break-all;
            @include text-clamp(1);
        }
    }

    .row_layout-department {
        flex: 1;
        @include text-clamp(1);
    }

    .row_layout-main-content {
        flex: 1;
    }

    .row_layout-department-item {
        margin-right: 16px;

        > .iai-icon {
            margin-left: 4px;
        }
    }

    .row_layout-authorise {
        flex: 150px 0 0;
    }

    .row_layout-180-width {
        flex: 180px 0 0;
    }
}

.template-staff-null {
    font-size: 14px;
    margin-left: 12px;
    color: #BDC1C7;
    margin-bottom: 0px;
}

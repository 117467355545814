.property-definition-container {
    display: flex;
    height: 100%;
    position: relative;

    .main-title.has-error {
        position: relative;
        overflow: visible;
    }

    .property-definition_name-error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 13px;
        line-height: 17px;
        white-space: nowrap;
        color: var(--error-color);
    }
}

.property-definition-sidebar {
    width: 179px;
}

.icon-selector-container {
    display: inline-flex;
    align-items: center;
    margin-right: 6px;

    &.selectable {
        cursor: pointer;
    }
}

.icon-selector-list {
    display: flex;
    width: 200px;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 16px;
}

.icon-selector-item {
    color: var(--gray-color);
    padding: 2px;

    &:hover,
    &.active {
        color: var(--primary-color);
        background-color: #F0F1F3;
    }
}

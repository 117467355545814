.backend_center-menu-container {
    display: flex;
    height: 100%;
    padding: 8px;

    &--performance {
        padding: 0px;
        background-color: #FFFFFF;
        .backend-menu-content-holder {
            margin: 0;
        }
    }
}

.backend_center-menu-sidebar {
    width: 153px;
    flex-shrink: 0;
    padding: 0px 8px;
    overflow: auto;
    background-color: white;
    border-radius: $base-border-radius;

    &--performance {
        background-color: #FAFAFA;
        border-radius: 0px;
    }

    &--performance-role {
        display: none;
    }
}

.backend-menu_item {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    color: $backend-base-color;
    padding: 0 8px;
    user-select: none;
    transition: background-color .5s ease-in-out;
    white-space: nowrap;

    &.active{
        background-color: rgba($color: $primary-color, $alpha: 0.1) !important;
    }
    &:hover {
        background-color: #edeeee;
    }

    &:hover,
    &:focus {
        color: $backend-base-color;
    }

    > span {
        vertical-align: middle;
    }

    &::after {
        display: inline-block;
        height: 100%;
        content: '';
        vertical-align: middle;
    }

    > .iai-icon {
        flex: none;

        &:first-child {
            margin-right: 6px;
        }

        &:last-child {
            margin-left: 6px;
        }
    }

    &:not(:last-child) {
        margin-bottom: 4px
    }
}

.backend-menu-item-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // height: 44px;
    overflow: hidden;
    padding: 0 8px;
    margin: 16px 0 8px;
    font-size: 12px;
    color: #9E9EA7;
}

.backend-menu-content-holder {
    overflow: hidden;
    flex: 1;
    background-color: white;
    margin-left: 8px;
    border-radius: $base-border-radius;

    &--performance-role {
        margin-left: 0px;
    }
}

.second-menu-sidebar {
    flex: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border-main-color;

    .second-menu-list {
        padding: 0 8px;
        flex: 1;
        overflow: auto;
        // .backend-menu_item.active {
        //     color: $primary-color !important;
        // }
    }

    .add-new-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        margin: 16px;
    }
}

.second-menu-header {
    padding: 16px 16px 12px;
}

.second-menu-item {
    .second-menu-label {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        + span {
            display: none;
        }
    }
    &.active,
    &:hover {
        .second-menu-label + span {
            display: block;
        }
    }
}

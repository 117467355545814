@charset 'UTF-8';

// Color Variables
$primary-color: #0067FF;
$danger-color: #ff3266;
$primary-color--hover: darken($primary-color, 3%);
$bg--gray: rgb(245, 245, 245);

// Btn Variables
$btn-primary-color: $primary-color;
$btn-primary-color--hover: darken($btn-primary-color, 3%);

// Page Variables
$border-main-color: #DFE4EA;

$base-background-color: #E7E7E7;
$backend-base-color: #1D2129;
$base-border-radius: 16px;
$horizontal-space: 16px;

// :root中 sass变量没有被转换
:root {
    --primary-color: #0067FF;
    --danger-color: #ff3266;
    --error-color: #ff4d4f;
    --gray-color: #4E5969;
}

.single-normal-user-container {
    display: flex;
    align-items: center;
    height: 56px;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px 17px 10px 12px;
    transition: background-color .3s ease-in-out;
    user-select: none;

    .staff-avatar {
        position: relative;
    }

    .avatar-tag {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);

        padding: 0 6px;
        background-color: $primary-color;
        border-radius: 8px;
        font-size: 12px;
        color: #FFFFFF;
        white-space: nowrap;
        line-height: 18px;

        &.role-manager {
            background-color: #45A759;
        }

        &.role-hrg {
            background-color: #FBBC05;
        }
    }

    .single_user-right-container {
        flex: 1;
        overflow: hidden;
        margin-left: 12px;
    }
}

.single_user-right-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.single_user-operation-container {
    display: flex;
    align-items: center;
}

.single-normal-user-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .single-normal-user-name {
        @include text-clamp(1);
        word-break: break-all;
    }

    .single-normal-user-extra {
        font-size: 12px;
        color: #9E9EA7;
        @include text-clamp(1);
    }
}

.admin-setting-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 24px 0;
}

.admin-setting-title {
    color: #0C0C22;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 16px;
}

.admin-setting-explain {
    color: #6E6D7A;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 32px;
}

.admin-setting-selection {
    position: relative;
    z-index: 1;
    width: 100%;

    .selection-options-container {
        position: absolute;
        height: 245px;
        overflow: auto;
        width: 100%;
        top: calc(100% + 2px);
        background: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        overflow: hidden;
    }

    .selection-options-content {
        height: 100%;
        width: 100%;
        overflow: auto;
    }
}

.admin_selection-value-container {
    display: flex;
    align-items: center;
    height: 50px;
    border: 1px solid $border-main-color;
    border-radius: 16px;
    padding: 0 16px;
    background-color: #FFFFFF;
    cursor: pointer;

    > .single-normal-user-container {
        height: 100%;
        flex: 1;
        padding: 0;
    }

    .admin_selection-value-arrow {
        font-size: 16px;
        transition: all 0.3s ease-out;

        &.open {
            transform: rotate(-180deg);
        }
    }
}

.admin-setting-operation-btn {
    width: 460px;
    margin-top: 32px;
}
